import React from 'react'
import { Link } from 'gatsby'
import { Button, Box, Heading, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  airplane: {
    display: `inline-block`,
    color: `alpha`
  },
  occupation: {
    mb: 4
  },
  specialty: {
    color: `text`,
    mb: 4
  }
}

export default () => (
  <>
    <Heading variant='h1'>
      <Text sx={styles.airplane}>Airplane</Text> Blog
    </Heading>
    <Heading variant='h3' sx={styles.specialty}>
      Tips, tricks, and simple steps to have an optimized server!
    </Heading>
    <Box variant='buttons.group'>
      <Button as={Link} to="https://discord.gg/3gtc45q" target="_blank">
        Join Discord
      </Button>
      <Button as={Link} variant='white' to="https://github.com/TECHNOVE/Airplane" target="_blank">
        GitHub Project
      </Button>
    </Box>
  </>
)
